import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { maxSatisfying } from "semver";




function WorkingTimeSign (props) {
    const { workingTimes, workingTimesTable, orderTypes, withoutFuture_delivery, withoutFuture_pickup, pickupTimes, deliveryTimes } = props.shop;
    const { t } = useTranslation();

    const [sign, setSign] = useState(''); 

    useEffect(()=> {
        const orderTypeParams = [
            {
                name: "delivery",
                times: deliveryTimes,
                noFuture: withoutFuture_delivery
            }, {
                name: "pickup",
                times: pickupTimes,
                noFuture: withoutFuture_pickup
            }
        ];

        const weeknames = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

        const weeknames2 = weeknames.map(weekname => weekname + '2');
        const weeknames_dup = weeknames.concat(weeknames2);

        let decision_data = {};

        decision_data.now = new Date();
        decision_data.now_date = String(decision_data.now.getDate()).padStart(2, '0') + '/' + String(decision_data.now.getMonth() + 1).padStart(2, '0') + '/' + decision_data.now.getFullYear();
        decision_data.now_weekday_num = decision_data.now.getDay();
        decision_data.now_weekday = weeknames[decision_data.now_weekday_num];
        decision_data.now_hour_minute = String(decision_data.now.getHours()).padStart(2, '0') + ':' + String(decision_data.now.getMinutes()).padStart(2, '0');

        decision_data.work = false;
        decision_data.work_now = false;
        decision_data.work_today = false;
        decision_data.work_from = '';
        decision_data.work_to = '';
        decision_data.work_date = '';

        decision_data.delivery = false;
        decision_data.delivery_now = false;
        decision_data.delivery_today = false;
        decision_data.delivery_future = false;
        decision_data.delivery_from = '';

        decision_data.pickup = false;
        decision_data.pickup_now = false;
        decision_data.pickup_today = false;
        decision_data.pickup_future = false;
        decision_data.pickup_from = '';

        if(workingTimesTable?.length>0) {

            decision_data.work = true;
            for(const workingTime of workingTimesTable) {
                const paramTimeDateObj = new Date(workingTime.date * 1000);
                const paramTimeDate = String(paramTimeDateObj.getDate()).padStart(2, '0') + '/' + String(paramTimeDateObj.getMonth() + 1).padStart(2, '0') + '/' + paramTimeDateObj.getFullYear();

                if(paramTimeDate == decision_data.now_date && decision_data.now_hour_minute >= workingTime.from && decision_data.now_hour_minute <= workingTime.to) {
                    decision_data.work_now = true;
                    decision_data.work_from = workingTime.from;
                    decision_data.work_to = workingTime.to;
                    break;
                } else if(paramTimeDate == decision_data.now_date && decision_data.now_hour_minute < workingTime.from)  {
                    decision_data.work_today = true;
                    decision_data.work_from = workingTime.from;
                    break;
                } else {
                    decision_data.work_from = workingTime.from;
                    decision_data.work_date = paramTimeDate;
                    break;
                }
            }

        } else if(workingTimes?.length>0) {

            const workingTimes2 = workingTimes.map(workingTime => {
                let workingTime2 = Object.assign({}, workingTime);
                workingTime2.weekday = workingTime2.weekday + '2';
                return workingTime2;
            });

            const workingTimesDup = workingTimes.concat(workingTimes2);

            decision_data.work = true;
            for(const workingTime of workingTimesDup) {
                if(workingTime.weekday == decision_data.now_weekday && decision_data.now_hour_minute >= workingTime.from && decision_data.now_hour_minute <= workingTime.to) {
                    decision_data.work_now = true;
                    decision_data.work_from = workingTime.from;
                    decision_data.work_to = workingTime.to;
                    break;
                } else if(workingTime.weekday == decision_data.now_weekday && decision_data.now_hour_minute < workingTime.from)  {
                    decision_data.work_today = true;
                    decision_data.work_from = workingTime.from;
                    break;
                } else if(weeknames_dup.indexOf(workingTime.weekday) > decision_data.now_weekday_num) {
                    decision_data.work_from = workingTime.from;
                    let work_date = new Date(decision_data.now.getTime());
                    work_date.setDate(work_date.getDate() + (weeknames_dup.indexOf(workingTime.weekday) - decision_data.now_weekday_num));
                    decision_data.work_date = String(work_date.getDate()).padStart(2, '0') + '/' + String(work_date.getMonth() + 1).padStart(2, '0') + '/' + work_date.getFullYear();
                    break;
                }
            }

        }

        if(decision_data.work_now) {

            for(const orderTypeParam of orderTypeParams) {

                const param_now = orderTypeParam.name + '_now';
                const param_today = orderTypeParam.name + '_today';
                const param_future = orderTypeParam.name + '_future';
                const param_from = orderTypeParam.name + '_from';

                if(orderTypes.includes(orderTypeParam.name)) {
                    decision_data[orderTypeParam.name] = true;
    
                    for(const paramTime of orderTypeParam.times) {
                        const paramTimeDateObj = new Date(paramTime.date * 1000);
                        const paramTimeDate = String(paramTimeDateObj.getDate()).padStart(2, '0') + '/' + String(paramTimeDateObj.getMonth() + 1).padStart(2, '0') + '/' + paramTimeDateObj.getFullYear();

                        if(paramTimeDate == decision_data.now_date && decision_data.now_hour_minute >= paramTime.from && decision_data.now_hour_minute <= paramTime.to) {
                            decision_data[param_now] = true;
                            break;
                        } else if(paramTimeDate == decision_data.now_date && decision_data.now_hour_minute < paramTime.from)  {
                            decision_data[param_today] = true;
                            decision_data[param_from] = paramTime.from;
                            decision_data[param_future] = orderTypeParam.noFuture == '0';
                            break;
                        } else {
                            decision_data[param_future] = orderTypeParam.noFuture == '0';
                            break;
                        }
                    }
                }
            }

        }

        let message = ''
        let message2 = '';

        if(!decision_data.work) {
            message = t('WorkingTimeIsntDefined');
        } else if(!decision_data.work_now) {
            if(!decision_data.work_today) {
                message = t('BranchOpen.NextDay', {date: decision_data.work_date, from: decision_data.work_from });
            } else {
                message = t('BranchOpen.Today', { from: decision_data.work_from });
            }
        } else {
            message = t('BranchOpen.Now', { from: decision_data.work_from, to: decision_data.work_to });
            if(!decision_data.delivery) {
                if(!decision_data.pickup) {
                    // *1
                    message2 = t('Delivery.Closed.PickupToo');
                } else if(decision_data.pickup_now) {
                    message2 = t('Delivery.Closed.PickupActiveNow');
                } else if(decision_data.pickup_today) {
                    if(!decision_data.pickup_future) {
                        message2 = t('Delivery.Closed.PickupWillBeActiveToday', { pickup_from: decision_data.pickup_from });
                    } else {
                        // *2
                        message2 = t('Delivery.Closed.PickupWillBeActiveSomeDay'); 
                    }
                } else {
                    if(decision_data.pickup_future) {
                        // *2
                        message2 = t('Delivery.Closed.PickupWillBeActiveSomeDay'); 
                    } else {
                        // *1
                        message2 = t('Delivery.Closed.PickupToo'); 
                    }
                }
            } else if(decision_data.delivery_now) {
                //...
            } else if(!decision_data.delivery_today) {
                if(decision_data.delivery_future) {
                    if(!decision_data.pickup) {
                        // *3
                        message2 = t('Delivery.WillBeActive.PickupClosed'); 
                    } else if(decision_data.pickup_now) {
                        message2 = t('Delivery.WillBeActive.PickupActiveNow'); 
                    } else if(decision_data.pickup_today) {
                        if(!decision_data.pickup_future) {
                            message2 = t('Delivery.WillBeActive.PickupWillBeActiveToday', {pickup_from: decision_data.pickup_from}); 
                        } else {
                            // *4
                            message2 = t('Delivery.WillBeActive.PickupWillBeActiveSomeDay');
                        }
                    } else {
                        if(decision_data.pickup_future) {
                            // *4
                            message2 = t('Delivery.WillBeActive.PickupWillBeActiveSomeDay');
                        } else {
                            // *3
                            message2 = t('Delivery.WillBeActive.PickupClosed'); 
                        }
                    }
                } else {
                    if(!decision_data.pickup) {
                        // *5
                        message2 = t('Delivery.Closed.PickupToo'); 
                    } else if(decision_data.pickup_now) {
                        message2 = t('Delivery.Closed.InFutureToo.PickupActiveNow'); 
                    } else if(decision_data.pickup_today) {
                        if(!decision_data.pickup_future) {
                            message2 = t('Delivery.Closed.InFutureToo.PickupWillBeActiveToday', { pickup_now: decision_data.pickup_from });
                        } else {
                            // *6
                            message2 = t('Delivery.Closed.InFutureToo.PickupWillBeActiveSomeDay'); 
                        }
                    } else {
                        if(decision_data.pickup_future) {
                            // *6
                            message2 = t('Devliery.Closed.InFutureToo.PickupWillBeActiveSomeDay');
                        } else {
                            // *5
                            message2 = t('Delivery.Closed.PickupToo');
                        }
                    }
                }
            } else {
                if(decision_data.delivery_future) {
                    //...
                } else {
                    if(!decision_data.pickup) {
                        // *7
                        message2 = t('Delivery.WillBeActiveToday.PickupClosed', { delivery_from: decision_data.delivery_from }); 
                    } else if(decision_data.pickup_now) {
                        message2 = t('Delivery.WillBeActiveToday.PickupActiveNow', { delivery_from: decision_data.delivery_from }); 
                    } else if(decision_data.pickup_today) {
                        if(!decision_data.pickup_future) {
                            message2 = t('Delivery.WillBeActvieToday.PickupClosedV2', { delivery_from: decision_data.delivery_from }); 
                        } else {
                            // *8
                            message2 = t('Delivery.WillBeActiveToday.PickupClosedV2', { delivery_from: decision_data.delivery_from }); 
                        }
                    } else {
                        if(decision_data.pickup_future) {
                            // *8
                            message2 = t('Delivery.WillBeActiveToday.PickupClosedV2', { delivery_from: decision_data.delivery_from }); 
                        } else {
                            // *7
                            message2 = t('Deivery.WillBeActiveToday.PickupClosed');
                        }
                    }
                }
            }
        }

        message = (message2 === '') ? message : (message + "\r\n" + message2);

        setSign(message);

    }, [workingTimes, workingTimesTable, orderTypes, withoutFuture_delivery, withoutFuture_pickup, pickupTimes, deliveryTimes, t]);

    return(
        <div className={ props.itemStyle } style={{ backgroundColor: props.mainColor }}>
            <div style={{ flex: '1', flexFlow: 'row wrap', minHeight: '30px',  color: 'var(--mainWhite)', padding: '5px', whiteSpace: 'pre-line' }}>
                {sign}
            </div>
        </div>
    );
}


export default WorkingTimeSign;